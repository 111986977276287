import React, { useEffect, useState, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { MingleSubmitButton } from "~/components/form/MingleSubmitButton";
import { DialogWindow, ITeam } from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";

declare let window: DialogWindow;

/**
 * Props for the StickySubmitBar component
 */
type StickySubmitBarProps = {
  activeTeam?: ITeam; // The current active team
  label: string; // Label for the submit button
  submitValue: string; // Value to submit
  hasAdminRights?: boolean; // Whether the user has admin rights
  isBoosted?: boolean | undefined; // Whether the team is boosted
  formHasChanges: boolean; // Whether the form has unsaved changes
  onDiscardChange: (e: boolean) => void; // Handler for discarding changes
  showAnim?: boolean; // Whether to show animation on the submit button
};

/**
 * StickySubmitBar - A component that displays a sticky bar at the bottom of the screen
 * for form submissions with save and discard actions
 */
export const StickySubmitBar = memo(
  ({
    activeTeam,
    submitValue,
    hasAdminRights,
    isBoosted,
    formHasChanges,
    label,
    onDiscardChange,
    showAnim,
  }: StickySubmitBarProps) => {
    const { t } = useTranslation("translation");

    // // State to control visibility of the bar
    // const [showBar, setShowBar] = useState<boolean>(formHasChanges);

    // State to control animation of the submit button
    const [showButtonAnim, setShowButtonAnim] = useState<boolean | undefined>(
      showAnim
    );

    // // Update the bar visibility when form changes
    // useEffect(() => {
    //   setShowBar(formHasChanges);
    // }, [formHasChanges]);

    // Update button animation when prop changes
    useEffect(() => {
      setShowButtonAnim(showAnim);
    }, [showAnim]);

    // Handle submit button click with associated validations
    const handleSubmitClick = useCallback(
      (e: React.MouseEvent) => {
        setShowButtonAnim(false);

        // Show boost level rights modal if team is not boosted
        if (!isBoosted) {
          // Uncomment to implement boost level restriction
          // e.preventDefault();
          // window.modalBoostLevelRights.showModal();
          // return;
        }

        // Show admin rights modal if user lacks admin rights
        if (!hasAdminRights) {
          e.preventDefault();
          window.modalAdminRights.showModal();
          return;
        }

        // Track the save changes action in MixPanel if needed
        if (activeTeam?.id) {
          MixPanel.track("mft_tap_button_saveChanges", {
            teamId: activeTeam.id,
          });
        }
      },
      [isBoosted, hasAdminRights, activeTeam]
    );

    // Handle discard button click
    const handleDiscardClick = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        onDiscardChange(false);
      },
      [onDiscardChange]
    );

    return (
      <>
        {/* Placeholder to maintain layout when bar appears */}
        <div
          className="sticky-submit-bar-placeholder h-20"
          aria-hidden="true"
        ></div>

        {/* Sticky footer bar with submit and discard buttons */}
        <footer
          className={`
          fixed z-[120]
          bottom-0
          w-full
          transform transition-all duration-200
          left-0
          lg:left-72
          lg:w-[calc(100%_-_288px)] bg-white h-20 dark:bg-black 
          border-t border-gray-100 dark:border-gray-800 
          xl:gap-2 flex-row justify-between xl:justify-end items-center
        `}
          role="region"
          aria-label="Form actions"
        >
          <div className="grid grid-cols-2 gap-4 pt-4 max-w-xl w-full m-auto px-3 lg:px-4 xl:px-6">
            {/* Discard changes button */}
            <button
              type="reset"
              className="btn btn-primary btn-outline btn-block"
              onClick={handleDiscardClick}
              aria-label={t("settingsPage.discard")}
            >
              {t("settingsPage.discard")}
            </button>

            {/* Submit button */}
            <MingleSubmitButton
              submitValue={submitValue}
              label={label}
              theme={
                showButtonAnim ? "btn-primary animation-pulse" : "btn-primary"
              }
              disabled={!formHasChanges}
              onClick={handleSubmitClick}
              aria-label={label}
            />
          </div>
        </footer>
      </>
    );
  }
);
